$(document).ready(() => {
  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .btn-prime').click(e => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    let topOffset = $(linkTarget).offset().top;
    let navContainer = '.fixed-top';
    if ($('.mobile-nav').is(':visible')) {
      if ($('#burger').is(':checked')) {
        navContainer = '.mobile-menu input~nav';
        $('#burger').trigger('click');
      }
    }

    topOffset -= $(navContainer).height();

    $('html, body').animate({
      scrollTop: topOffset,
    }, 900);
  });

  $('.slideshow').slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 300,
    // centerMode: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    }, ],
  });

  // Wheel Click Modal
  $('button.btn-prime').on('mousedown', e => {
    if (e.which === 2) {
      e.preventDefault();
      e.stopPropagation();
      $(e.currentTarget).trigger('click');
    }
  });

  //   Mobile Iframe

 const resizeFormIframe = () => {
     const modalHeaderHeight = $('.modal-header').height();

    $('.iframe-form').css('height', window.innerHeight - modalHeaderHeight);
    console.log(modalHeaderHeight);
  };


  window.onresize = () => {
    resizeFormIframe();
  };

  $('#forModal').on('shown.bs.modal', function () {
    resizeFormIframe();
  });

});
